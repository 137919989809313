import React from "react"
import { I18nextProvider } from "react-i18next"
import { PageContextProvider } from "./src/context/PageContext"
import UserContextProvider from "./src/context/UserContext"
import { i18next } from "./src/i18n/i18next"

/**
 * gatsby-ssr ja gatsby-browser API metodit
 */

/**
 * Add Leadoo chatbot script to the head of every page
 */
export const onRenderBody = ({ setHeadComponents, setPostBodyComponents }) => {
  setHeadComponents([
    <script
      key="leadoo-script"
      type="text/javascript"
      async
      src="https://bot.leadoo.com/bot/dynamic.js?company=EoXZQnIT"
    />,
  ])

  setPostBodyComponents([
    <script
      key="leadoo-analytics-script"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          window.addEventListener('CookiebotOnAccept', function (e) {
            if (Cookiebot.consent.statistics) {
              console.log('statistics consent');
              if (!window.ldanalytics)
                window.ldanalytics = [];
              window.ldanalytics.push(function(a) {
                a.toggleTracking(true, false);
              });
            }
          }, false);
        `,
      }}
    />,
  ])
}

export const wrapRootElement = ({ element }) => {
  // console.debug("wrapRootElement", element, options)

  /**
   * Gatsby: "Set up any Provider components that will wrap your application"
   *
   * Injektoidaan i18next instanssi I18NextProvider komponenttiin: https://react.i18next.com/latest/i18nextprovider
   * I18next: "You will only need to use the provider in scenarios for SSR (ServerSideRendering) or if you need to support multiple i18next instances - eg. if you provide a component library. "
   *
   * i18next laitetaan root elementin ulkopuolelle koska
   * - rootin ulkopuolella komponentit ei vaihdu navigoidessa sivulta toiselle: sitä ei tarvitse alustaa kuin kerran
   * - I18NextProvider dokumentaatiosta: "When to use: You will only need to use the provider in scenarios for SSR"
   * - Gatsby dokumentaatio sanoo, että wrapRootElement on oikea paikka lisätä Provider komponentit
   */

  return <I18nextProvider i18n={i18next}>{element}</I18nextProvider>
}

export const wrapPageElement = ({ element, props }) => {
  // console.debug("wrapPageElement props", element, props)
  return (
    <UserContextProvider>
      <PageContextProvider value={props.pageContext}>{element}</PageContextProvider>
    </UserContextProvider>
  )
}
